import DropdownStyled from "components/util/dropdownStyled/dropdownStyled";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { changeLanguage } from "utils/util";
import LanguageSelectorStyle from "./languageSelectorStyle";

const LanguageSelector = () => {
  const router = useRouter();
  const editMode = useSelector((state) => state.cms.editMode);
  const languages = process.env.NEXT_PUBLIC_ACTIVE_LOCALES.split(",");
  const { t: tPublic } = useTranslation("public");

  // TODO this could be removed as soon as the end user language selector is
  // not shown to the content manager in content manager pages like "/cms/forms/create"
  const isLanguageSelectorDisabled = () => {
    // console.log(router.asPath);
    switch (router.asPath) {
      case "/cms/navigations/create":
      case "/cms/forms/create":
        return true;
      default:
        return false;
    }
  };

  return (
    <div className="language-selector-wrapper">
      <DropdownStyled
        disabled={editMode || isLanguageSelectorDisabled()}
        defaultValue={router.locale}
        name="cms-language-selector"
        dropdownList={languages.map((lang) => {
          return {
            value: lang,
            name: lang.toUpperCase(),
          };
        })}
        onChange={(e) => changeLanguage(e.target.value, router)}
        ariaLabel={tPublic("language")}
      />
      <LanguageSelectorStyle />
    </div>
  );
};

export default LanguageSelector;
