import dynamic from "next/dynamic";
import { useState } from "react";

const ListItemIcon = dynamic(() => import("@mui/material/ListItemIcon"));
const MenuItem = dynamic(() => import("@mui/material/MenuItem"));
const Select = dynamic(() => import("@mui/material/Select"));

/**
 *
 * @param {*} value
 * @param {*} defaultValue
 * @param {[]} dropdownList
 * @param {Function} onChange
 * @param {String} itemNameKey
 * @param {String} itemValueKey
 * @param {String} emptyValue
 * 
 * @example
 * 
 *   const dummyData = [
        { specialName: "Test 123", specialValue: "Toastbrot" },
        {name: "Test 456", value: "Vollkornbrot"},
        "Test 789",
        "Test 012",
        "Test 654",
      ];
 * 
 * <DropdownStyled
      dropdownList={dummyData}
      defaultValue="Test 123"
      onChange={(e) => console.log(e.target.value)}

      // optional if there is an object with value / name
      // or the array contains just strings
      itemNameKey="specialName"
      itemValueKey="specialValue"
      emptyValue="Nichts"
    />
 *
 */
const DropdownStyled = ({
  dropdownList,
  defaultValue,
  value: valueFromProps,
  onChange: onChangeFromProps,
  itemNameKey,
  itemValueKey,
  emptyValue,
  disableEmptyValue,
  name,
  id,
  disabled,
  customTransformOrigin,
  ariaLabel
}) => {
  const isControlled = typeof valueFromProps !== "undefined";
  const hasDefaultValue = typeof defaultValue !== "undefined";

  const [internalValue, setInternalValue] = useState(
    hasDefaultValue ? defaultValue : ""
  );

  const value = isControlled ? valueFromProps : internalValue;

  const onChange = (e) => {
    if (onChangeFromProps) {
      onChangeFromProps(e);
    }
    if (!isControlled) {
      setInternalValue(e.target.value);
    }
  };

  /**
   * get the value of the dropdown item. the value can be
   * different in dropdownStyled use cases
   * @param {*} item
   * @returns
   */
  const getValue = (item) => {
    if (item[itemValueKey]) {
      return item[itemValueKey];
    } else if (item.value) {
      return item.value;
    } else if (item.value === 0) {
      return item.value;
    }
    return item;
  };

  return (
    <>
      <Select
        aria-label={ariaLabel || ""}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        fullWidth
        displayEmpty
        disabled={disabled}
        variant="outlined"
        MenuProps={{
          disablePortal: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            ...(customTransformOrigin
              ? customTransformOrigin
              : { vertical: "top", horizontal: "left" }),
          },
        }}
      >
        {emptyValue && (
          <MenuItem
            disabled={disableEmptyValue}
            className="dropdown-style-menu-item"
            value=""
          >
            <em>{emptyValue}</em>
          </MenuItem>
        )}
        {dropdownList &&
          dropdownList.map((item, index) => {
            return (
              <MenuItem
                className="dropdown-style-menu-item"
                key={index}
                value={getValue(item)}
                disabled={item.disabled}
              >
                {item.icon && (<ListItemIcon>{item.icon}</ListItemIcon>)}
                {item[itemNameKey] || item.name || item}
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default DropdownStyled;
