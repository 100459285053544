import { globalSettings } from "services/globalSettings/globalSettingsService";

export default function LanguageSelectorStyle() {
  const settings = globalSettings.languageswitch;
  const backgroundColor = settings.backgroundColor
    ? settings.backgroundColor
    : "#fff";
  const borderColor = settings.borderColor ? settings.borderColor : "lightblue";
  const fontColor = settings.fontColor ? settings.fontColor : "#000";
  const hoverBackgroundColor = settings.hoverBackgroundColor
    ? settings.hoverBackgroundColor
    : "#2a3035";
  const hoverFontColor = settings.hoverFontColor
    ? settings.hoverFontColor
    : "#fff";
  return (
    <style jsx global>{`
      .language-selector-wrapper {
        width: 80px;
        position: relative;
        padding: 0.4rem;

        #mui-component-select-cms-language-selector {
          display: flex;
          align-items: center;
        }

        .MuiInput-root {
          overflow: hidden;
          color: ${fontColor} !important;
          .MuiSvgIcon-root {
            color: ${fontColor} !important;
          }
          background-color: ${backgroundColor} !important;
          border-radius: 4px;
          &.MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 1px solid ${borderColor} !important;
          }
          &::before {
            border: 1px solid ${borderColor};
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
          &::after {
            border-radius: 0;
            border-bottom: 2px solid ${borderColor} !important;
          }
          &.Mui-focused {
            &::after {
              border-radius: 0;
              border-bottom: 2px solid ${borderColor} !important;
            }
          }
          &.MuiInput-underline::after {
            border-bottom: 2px solid ${borderColor} !important;
          }

          .MuiPaper-root {
            border: 1px solid ${borderColor};
            border-radius: 4px;
            .MuiList-root {
              background-color: ${backgroundColor} !important;
              .MuiMenuItem-root {
                color: ${fontColor};
                &:hover {
                  color: ${hoverFontColor};
                  background-color: ${hoverBackgroundColor};
                }
              }
              .MuiMenuItem-root:not(:last-child) {
                border-bottom: 1px solid ${borderColor} !important;
              }
            }
          }
        }

        /* Bottom border will disapear without this override. */
        .MuiInput-root.MuiInput-underline.Mui-disabled::before {
          border-bottom: 1px solid ${borderColor} !important;
        }
      }
      /* Bottom border of disabled (guest)-language-selector will disapear without this override. */
      header {
        .language-selector-wrapper {
          .MuiInput-root.MuiInput-underline.Mui-disabled::before {
            border-bottom: 1px solid ${borderColor} !important;
          }
        }
      }
    `}</style>
  );
}
